<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Footer from "../../../components/TheFooter";
import Navbar from "../../../components/TheNavbar";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "EditLeadDetail",
  components: { Navbar, Footer, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "2.5 How to edit Lead details?",
          explanation: [
            {
              expTitle: "",
              expDesc: "1. You can edit their contact details by tapping the “Edit” button on the top right of the screen. \n",
              imgType: true,
              img: "44.Contact info_tab 2.png",
            },
            {
              expTitle: "",
              expDesc: "2. You will see the edit contact page with editable fields",
              imgType: true,
              img: "57-1. Create contact [full + delete].png",
            },
            {
              expTitle: "Name:",
              expDesc: "Displayed within the Kloser app only for your reference and searching, but never shown to clients. Usually the same as the contact name in your phonebook. This field is required.",
              imgType: false,
            },
            {
              expTitle: "Mobile Number:",
              expDesc: "The phone number of your contact, typically used to send content quickly via apps like WhatsApp or SMS. This field is required.",
              imgType: false,
            },
            {
              expTitle: "Notes:",
              expDesc: "Notes are for you to remember details about the contact, such as their preferences. These are private to you, and never shown to your clients. This field is optional.",
              imgType: false,
            },
            {
              expTitle: "Lead status:",
              expDesc: "The status of this lead. A newly added contact will be marked as \"New\" for 30 days by default. If there are activities between you and the lead, the status will change to \"In progress\". If the deal has been closed, you can change the status to \"Closed\"",
              imgType: false,
            },
            {
              expTitle: "Delete:",
              expDesc: "You can always delete the contact at any time by tapping on the red “Delete” button at the bottom. This will permanently remove it from your lead/group along with any associated data. However, this action won't delete the contacts stored in your mobile phonebook.",
              imgType: false,
            },
            {
              expTitle: "",
              expDesc: "3. Once you’re done editing, tap the “Save” button on the top right to save the change on the contact. ",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
